import React from 'react'

const Footer = () => {
  return (
    <footer className="footer" style={{backgroundColor:"#04243d"}}>
    <div className="container-box">
      <div className="row py-4 text-md-start text-center">
        <div className="col-md-6 my-2 paragraph text-white">
          <span>
            {" "}
            Copyrights © {new Date().getFullYear()} - All Rights Reserved
          </span>
        </div>
        <div className="col-md-6 my-2 paragraph text-white text-md-end">
          <span>
            Designed and Developed by{" "}
            <a
              href="https://www.zithas.com"
              target="_blank"
              rel="noopener"
              className="text-decoration-none text-green"
              style={{ color: "#ffbf1e", fontWeight: "bold" }}
            >
              Zithas Technologies
            </a>
          </span>
        </div>
      </div>
    </div>
  </footer>
  )
}

export default Footer
