import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../assets/css/Navbar.css"
import dolphin from "../assets/Images/dolphinlogo.png"

const Navbar = ({scrollToSection}) => {

  const [stickyNavbar, setStickyNavbar] = useState(false);
  const [showsidebar, setShowsidebar] = useState(false);

  window.addEventListener('scroll', () => {
    if (window.scrollY > 100) {
      setStickyNavbar(true);
    } else {
      setStickyNavbar(false);
    }
  })

  const handleLinkClick = (section) => {
    if (scrollToSection) {
      scrollToSection(section);
    }
  };

  useEffect(() => {
    // Update body overflow style based on showsidebar state
    if (showsidebar) {
      document.body.style.overflow = "hidden"; // Disable scrolling
    } else {
      document.body.style.overflow = ""; // Enable scrolling
    }
  }, [showsidebar]);

  // getting user location through ip address
  //   useEffect(() => {
  //     async function getLocation() {
  //         let location = await fetch('https://ipapi.co/json');
  //         let data = await location.json();
  //         console.log(data)
  //     }
  //     getLocation()
  // }, [])
  

  return (
    <div >
       <nav className={`navbar ${stickyNavbar && 'stickyNav'}`}>
        
      <div className="navbar__left">
        <img src={dolphin} alt="Logo" className="navbar__logo" />
        <h2 className="logo__text">DOLPHIN MARKETING</h2>
      </div>
      <div className="navbar__right">
      <ul className="navbar__nav">
          <li className="navbar__item" onClick={() => handleLinkClick("home")}>Home</li>
          <li className="navbar__item" onClick={() => handleLinkClick("services")}>Services</li>
          <li className="navbar__item" onClick={() => handleLinkClick("about")}>About</li>
         
          <li className="navbar__item" onClick={() => handleLinkClick("contact")}>Contact</li>
        </ul>
        <button className="navbar__button" onClick={() => handleLinkClick("contact")}>Get in Touch
        <i class="bi bi-arrow-up-right ms-2"></i>


        </button>
      </div>
     
    </nav>

    <nav className="navbar2">
      <div className="container-box">
      <div className="row justify-space-between align-items-center">
      <div className="col-6 text-md-start text-center">
              <Link to="#" className="navbar-brand" >
                {/* <img src={logo} alt="Logo" width="175" onClick={() => window.scrollTo(0, 0)}></img> */}
                <img src={dolphin} alt="Logo" className="navbar__logo"/>
              </Link>
            </div>
            <div className="col-6 navbar2-icon">
                <i className="bi bi-list" onClick={() => setShowsidebar(true)}></i>
            </div>


        </div>
        </div>
      </nav>


      {/* sidebar start */}
      <div className={showsidebar ? "sidebar open" : "sidebar"}>
          <div className="d-flex justify-content-between" style={{ background: "#04243d" }}>

            {/* logo */}
            <Link to={`/`} className="text-decoration-none">
            <div class="d-flex navbar-brand py-4 align-items-center" style={{paddingLeft:"9px",}}>
            
              <img
              src={dolphin}
              alt="dolphin marketing"
              height="50px"
              className="object-fit-cover me-2"
            /> <h2 className="site-branding-text text-center mb-0 fs-3">
                Marketing
              </h2>
              

            </div>
            </Link>
            <div className="d-flex justify-content-end ">
              <button
                className="btn btn primary"
                onClick={() => setShowsidebar(false)}
              >
                <h2 className="text-white">
                  <i class="bi bi-x"></i>
                </h2>
              </button>
            </div>
          </div>
          <ul>
            <li className="main-li">
              <div
                className="accordion accordion-sidebar"
                id="services-accordion"
              >
                <div className="accordion-item accordion-item2">
                  <h2 className="accordion-header" id="services-heading">
                    <button
                      className="accordion-button accordion-button2"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#services-collapse"
                      aria-expanded="false"
                      aria-controls="services-collapse"
                    >
                      Services
                     
                    </button>
                  </h2>
                  <div
                    id="services-collapse"
                    className="accordion-collapse collapse"
                    aria-labelledby="services--heading"
                    data-bs-parent="#services-accordion"
                  >
                    <div className="accordion-body ">
                      <ul>
                        <li onClick={() => handleLinkClick("services")}>
                          <Link to={``}>
                            Loan
                          </Link>
                        </li>
                        <li
                          onClick={() => handleLinkClick("services")}
                        >
                          <Link to={``}>
                            Mortgage
                          </Link>
                        </li>
                        <li onClick={() => handleLinkClick("services")}>
                          <Link to={``}>
                          Mortgage
                          </Link>
                        </li>
                        <li
                          onClick={() => handleLinkClick("services")}
                        >
                          <Link to={``}>
                          Mortgage
                          </Link>
                        </li>
                        <li onClick={() => handleLinkClick("services")}>
                          <Link to={``}>
                          Mortgage
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            <li className="main-li">
              <div
                className="accordion accordion-sidebar"
                id="about-us-accordion"
              >
                <div className="accordion-item">
                  <h2
                    className="accordion-header"
                    id="about-us-heading"
                  >
                    <button
                      className="accordion-button accordion-button2"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#about-us-collapse"
                      aria-expanded="false"
                      aria-controls="about-us-collapse"
                    >
                      About Us
                      
                    </button>
                  </h2>
                  <div
                    id="about-us-collapse"
                    className="accordion-collapse collapse"
                    aria-labelledby="about-us-heading"
                    data-bs-parent="#about-us-accordion"
                  >
                    <div className="accordion-body">
                      <ul>
                        <li onClick={() => handleLinkClick("aboutus")}>
                          <Link
                            to={``}
                          >
                            Aboutus 
                          </Link>
                        </li>
                        <li onClick={() => handleLinkClick("aboutus")}>
                          <Link
                            to={``}
                          >
                            Aboutus
                          </Link>
                        </li>
                       
                       
                       
                       
                       
                        
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </li>
            
           
          </ul>
         
        </div>
        {/* sidebar end */}


      
    </div>
  );
};

export default Navbar;
