import React from 'react'
import "../assets/css/Loader.css"
const Loader = () => {
  return (
    <div className='loader1'>
      <div class="spinner">
  <div></div>   
  <div></div>    
  <div></div>    
  <div></div>    
  <div></div>    
  <div></div>    
  <div></div>    
  <div></div>    
  <div></div>    
  <div></div>    
</div>
    </div>
  )
}

export default Loader