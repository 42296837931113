import React, { useState, useEffect } from 'react';

const CounterUp = ({ endNumber, speed }) => {
  const [currentNumber, setCurrentNumber] = useState(0);
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (currentNumber < endNumber) {
        setCurrentNumber(prevNumber => prevNumber + 1);
      } else {
        clearInterval(interval);
      }
    }, speed);

    return () => clearInterval(interval);
  }, [currentNumber, endNumber, speed]);

  return (
    <span>{currentNumber}</span>
  );
};

const FactItem = ({ icon, alt, endNumber, title }) => {
  return (
    <div className="col-md-3 col-sm-6 ts-facts">
      <div className="ts-facts-img">
      <i class={icon}></i>

      </div>
      <div className="ts-facts-content">
        <h2 className="ts-facts-num"><CounterUp endNumber={endNumber} speed={10} /></h2>
        <h3 className="ts-facts-title">{title}</h3>
      </div>
    </div>
  );
};

const FactsWrapper = () => {
  return (
    <div className="facts-wrapper">
      <div className="row">
        <FactItem icon="bi bi-calendar" alt="facts-img" endNumber={23} title="Years of Industry Experience" />
        <FactItem icon="bi bi-cart-fill" alt="facts-img" endNumber={200} title="Products Sold" />
        <FactItem icon="bi bi-person-fill" alt="facts-img" endNumber={300} title="Customers Across India" />
        <FactItem icon="bi bi-laptop" alt="facts-img" endNumber={40} title="Industry Products Dealtors" />
      </div>
    </div>
  );
};

export default FactsWrapper;