import React, { useEffect, useRef, useState } from "react";
import Navbar from "./Navbar";
import "../assets/css/Home.css";
import "aos/dist/aos.css";
import "../assets/css/Modal.css";
import Footer from "./Footer";
import Slider from "react-slick";
import marketing2 from "../assets/Images/marketing2.jpg";
import marketing3 from "../assets/Images/marketing3.jpg";
import contactus from "../assets/Images/contactus.jpg";
import loan from "../assets/Images/icons8-loan-64.png"
import education from "../assets/Images/icons8-education-30.png"
import "../assets/css/Card.css";
import FactsWrapper from "./Counter";
// slick slider
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import axios from "axios";
import Swal from "sweetalert2";
import Loader from "./Loader";

const Index = () => {
  const [slidIndex, setSlidIndex] = useState(0);
  const [loading, setLoading] = useState(false);
  const sliderRef = useRef(null);
  const section2Ref = useRef(null);
  const section3Ref = useRef(null);
  const section4Ref = useRef(null);

  const [formdata, setFormdata] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    comment: "",
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    setFormdata({
      ...formdata,
      [e.target.name]: e.target.value,
    });
    // Remove error message when correcting input
    setErrors((prevErrors) => ({
      ...prevErrors,
      [e.target.name]: null,
    }));
  };

  useEffect(() => {
    // fetchInfo();
    window.scrollTo(0, 1);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Activate loader

    // Validate form fields
    const validationErrors = validateForm(formdata);

    if (Object.keys(validationErrors).length === 0) {
      setLoading(true);
      const formData = new FormData();

      formData.append("name", formdata.name);
      formData.append("email", formdata.email);
      formData.append("phone", formdata.phone);
      formData.append("subject", formdata.subject);
      formData.append("comment", formdata.comment);

      await axios
        .post(`https://dolphinmarketing.live/api/connect.php`, formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          console.log("Form data posted successfully:", response.data);
          // Clear form data after successful submission
          setFormdata({
            name: "",
            email: "",
            phone: "",
            subject: "",
            comment: "",
          });
          Swal.fire({
            title: "",
            text: "Your Data Submitted Successfully. ",
            icon: "success",
          });

          // Deactivate loader
          setLoading(false);
        })
        .catch((error) => {
          console.error("Error posting form data:", error);
          // Deactivate loader
          setLoading(false);
          Swal.fire({
            title: "",
            text: "Error! While Submitting form data",
            icon: "error",
          });
        });
    } else {
      setErrors(validationErrors);
      setLoading(false);
    }
  };

  const validateForm = (formdata) => {
    let errors = {};
    if (!formdata.name.trim()) {
      errors.name = "Name Is Required";
    }
    if (!formdata.email.trim()) {
      errors.email = "Email Is Required";
    } else if (!/\S+@\S+\.\S+/.test(formdata.email)) {
      errors.email = "Email Address Is Invalid";
    }
    if (!formdata.phone.trim()) {
      errors.phone = "Phone Number Is Required";
    } else if (!/^\d{10}$/.test(formdata.phone)) {
      errors.phone = "Please Enter Valid Phone Number ";
    }
    if (!formdata.subject.trim()) {
      errors.subject = "Subject Is Required";
    }
    if (!formdata.comment.trim()) {
      errors.comment = "Comment Is Required";
    }
    return errors;
  };

  const scrollToSection = (section) => {
    let targetSection = null;

    switch (section) {
      case "home":
        targetSection = sliderRef.current;

        break;
      case "about":
        targetSection = section2Ref.current;

        break;
      case "services":
        targetSection = section4Ref.current;

        break;
      case "contact":
        targetSection = section3Ref.current;

        break;

      default:
        break;
    }

    if (targetSection) {
      const topOffset = targetSection.offsetTop;
      const offset = 90;
      const scrollOptions = {
        top: topOffset - offset,
        behavior: "smooth",
      };
      // targetSection.scrollIntoView(scrollOptions);
      window.scrollTo(scrollOptions);
    }
  };

  const settings = {
    dots: false,
    fade: true,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    arrows: false,
    autoplaySpeed: 1000,
    beforeChange: (current, next) => setSlidIndex(next),
  };

  return (
    <div className="outer-container">
      {loading && <Loader />}
      <Navbar scrollToSection={scrollToSection} />

      {/* Banner section */}
      <Slider ref={sliderRef} {...settings}>
        <div className="carousel-slide">
          <img src={marketing2} alt="carouseImage" />
          <div className="bg-overlay"></div>
          <div
            className={`slider-content customFadeUp
             text-left`}
          >
            <h5 class="pbmit-sub-title transform-left transform-delay-1">
              Think Different, Execute Better
            </h5>
            <h2>
              home <br />
              <span> empowerment</span>
            </h2>
            <p className="mt-4">True Relationships Developing Future</p>
          </div>
        </div>
      </Slider>

      <div class="container-box pt-5 facts-container" ref={section4Ref}>
        <div class="container mt-5 mb-5">
          <p class="container-title">
            Here are the Services
            <br />
            we’re proud of
          </p>

          <div class="gradient-cards">
            <div class="card" >
              <div class="container-card bg-green-box" data-aos="fade-right"
                  data-aos-delay="400">
                <svg
                  width="80"
                  height="80"
                  viewBox="0 0 120 120"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                   <defs>
    {/* Define the image pattern */}
    <pattern id="image-pattern" width="100%" height="100%">
      <image href={loan} x="6" y="10" width="100" height="100"/>
    </pattern>
  </defs>
                  <rect
                    x="1"
                    y="1"
                    width="118"
                    height="118"
                    rx="24"
                    fill="url(#paint0_linear_1366_4565)"
                    fill-opacity="0.15"
                    stroke="url(#paint1_radial_1366_4565)"
                    stroke-width="2"
                  ></rect>

<rect
                    x="1"
                    y="1"
                    width="118"
                    height="118"
                    rx="24"
                    fill="url(#image-pattern)"
                    fill-opacity="1"
                    stroke="url(#paint1_radial_1366_4565)"
                    stroke-width="2"
                  ></rect>
                  
                  <defs>
                    <linearGradient
                      id="paint0_linear_1366_4565"
                      x1="0"
                      y1="0"
                      x2="120"
                      y2="120"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="white" stop-opacity="0.7"></stop>
                      <stop
                        offset="0.505208"
                        stop-color="white"
                        stop-opacity="0"
                      ></stop>
                      <stop
                        offset="1"
                        stop-color="white"
                        stop-opacity="0.7"
                      ></stop>
                    </linearGradient>
                    <radialGradient
                      id="paint1_radial_1366_4565"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(60 60) rotate(96.8574) scale(122.674 149.921)"
                    >
                      <stop stop-color="white"></stop>
                      <stop
                        offset="1"
                        stop-color="#363437"
                        stop-opacity="0.2"
                      ></stop>
                    </radialGradient>
                  </defs>
                </svg>
                <p class="card-title">Mortgage Loan</p>
                <p class="card-description">
                  Embark on the journey to homeownership with our comprehensive
                  mortgage loan solutions. Whether you're a first-time buyer or
                  looking to refinance, our expert team is here to guide you
                  every step of the way.
                </p>
              </div>
            </div>

            <div class="card">
              <div class="container-card bg-white-box " data-aos="fade-left"
                  data-aos-delay="400">
                <svg
                  width="80"
                  height="80"
                  viewBox="0 0 120 120"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >

<defs>
    {/* Define the image pattern */}
    <pattern id="image-pattern2" width="100%" height="100%">
      <image href={education} x="12" y="10" width="80" height="80"/>
    </pattern>
  </defs>
                  <rect
                    x="1"
                    y="1"
                    width="118"
                    height="118"
                    rx="24"
                    fill="url(#paint0_linear_1366_4565)"
                    fill-opacity="0.15"
                    stroke="url(#paint1_radial_1366_4565)"
                    stroke-width="2"
                  ></rect>

<rect
                    x="1"
                    y="1"
                    width="118"
                    height="118"
                    rx="24"
                    fill="url(#image-pattern2)"
                    fill-opacity="1"
                    stroke="url(#paint1_radial_1366_4565)"
                    stroke-width="2"
                  ></rect>
                  
                  <defs>
                    <linearGradient
                      id="paint0_linear_1366_4565"
                      x1="0"
                      y1="0"
                      x2="120"
                      y2="120"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stop-color="white" stop-opacity="0.7"></stop>
                      <stop
                        offset="0.505208"
                        stop-color="white"
                        stop-opacity="0"
                      ></stop>
                      <stop
                        offset="1"
                        stop-color="white"
                        stop-opacity="0.7"
                      ></stop>
                    </linearGradient>
                    <radialGradient
                      id="paint1_radial_1366_4565"
                      cx="0"
                      cy="0"
                      r="1"
                      gradientUnits="userSpaceOnUse"
                      gradientTransform="translate(60 60) rotate(96.8574) scale(122.674 149.921)"
                    >
                      <stop stop-color="white"></stop>
                      <stop
                        offset="1"
                        stop-color="#363437"
                        stop-opacity="0.2"
                      ></stop>
                    </radialGradient>
                  </defs>
                </svg>
                <p class="card-title">Education </p>
                <p class="card-description">
                That's fantastic! Providing education in the USA allows you to contribute to the development and growth of individuals and communities. Whether it's through traditional schooling, online programs, vocational training, or specialized courses, your efforts can make a meaningful impact on the lives of learners and the broader society.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-box">
        <FactsWrapper />
      </div>
      <div className="container-box about-container" ref={section2Ref}>
        <div className="row g-0">
          <div class="col-md-12 col-xl-6">
            <div
              class="pbmit-col-stretched-left"
              style={{ marginleft: "-8px" }}
            >
              <img
                src={marketing3}
                class="img-fluid w-100"
                alt=""
                data-aos="zoom-in"
                data-aos-delay="104"
              />
            </div>
          </div>
          <div className="col-md-12 col-xl-6">
            <div className="about-us-four-content">
              <h4 className="pbmit-subtitle">Marketing Agency</h4>
              <h2 className="pbmit-title">
                Boost Your Business with Dolphin Marketing
              </h2>
              <p>
                Welcome to Dolphin Marketing, your trusted partner in navigating
                the mortgage landscape in the USA.{" "}
              </p>
              <p>
                Whether you're a first-time homebuyer or looking to refinance
                your existing mortgage, Dolphin Marketing is here to help.
              </p>
              <p>
                Our team of experienced professionals will work closely with you
                to understand your unique needs and financial goals, guiding you
                every step of the way.
              </p>
              <p>
                Take the first step towards homeownership with Dolphin Marketing
                by your side. Contact us today to learn more about our services
                and how we can help you achieve your homeownership goals. Let us
                turn your dreams of owning a home into a reality.
              </p>
            </div>
          </div>
          <div className="col-md-12 about-us-four-contentbottom">
            <p>
              We offer a wide range of mortgage solutions tailored to suit your
              individual requirements. From conventional loans to FHA, VA, and
              USDA loans, we have the expertise to find the perfect financing
              option for you. With our expert guidance and personalized
              approach, we make the mortgage process smooth and hassle-free.
            </p>
          </div>
        </div>
      </div>

      <div className="container-box" ref={section3Ref}>
        <div className="row g-0 position-relative pb-5">
          <div className="col-md-4 contact-us-col1">
            <h4>Contact Us</h4>
            <h2>Happy To Answer Your Questions</h2>
            <div className="contact-form-leftbox">
              <img src={contactus}></img>
            </div>
          </div>

          <div className="col-md-8 contact-us-col2">
            <div className="contact-form-main">
              <div className="contact-form">
                <form
                  method="post"
                  className="contact-form"
                  id="contact-form"
                  noValidate
                >
                  <h2>Get in Touch with us!</h2>
                  <div className="row">
                    <div className="col-md-6 form-input-container">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Your Name"
                        name="name"
                        value={formdata.name}
                        onChange={handleChange}
                        required
                      />
                      {errors.name && (
                        <span className="text-danger">{errors.name}</span>
                      )}
                    </div>
                    <div className="col-md-6 form-input-container">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Email Address"
                        name="email"
                        value={formdata.email}
                        onChange={handleChange}
                        required
                      />
                      {errors.email && (
                        <span className="text-danger">{errors.email}</span>
                      )}
                    </div>
                    <div className="col-md-6 form-input-container">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Phone Number"
                        name="phone"
                        value={formdata.phone}
                        onChange={handleChange}
                        required
                      />
                      {errors.phone && (
                        <span className="text-danger">{errors.phone}</span>
                      )}
                    </div>
                    <div className="col-md-6 form-input-container">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Subject"
                        name="subject"
                        value={formdata.subject}
                        onChange={handleChange}
                        required
                      />
                      {errors.subject && (
                        <span className="text-danger">{errors.subject}</span>
                      )}
                    </div>
                    <div className="col-md-12 form-input-container">
                      <textarea
                        name="comment"
                        cols="40"
                        rows="10"
                        className="form-control"
                        placeholder="Comment"
                        value={formdata.comment}
                        required
                        onChange={handleChange}
                      ></textarea>
                      {errors.comment && (
                        <span className="text-danger">{errors.comment}</span>
                      )}
                    </div>
                    <div className="col-sm-12">
                      <button
                        type="submit"
                        className="pbmit-btn pbmit-btn-shape-round"
                      >
                        <i className="form-btn-loader fa fa-circle-o-notch fa-spin fa-fw margin-bottom d-none"></i>
                        <span
                          className="pbmit-button-content-wrapper d-flex align-items-center"
                          onClick={handleSubmit}
                        >
                          <span className="pbmit-button-text me-2">
                            Submit Now
                          </span>
                          <span className="pbmit-button-icon pbmit-align-icon-right">
                            <i className="bi bi-arrow-right"></i>
                          </span>
                        </span>
                      </button>
                    </div>
                    <div className="col-md-12 col-lg-12 message-status"></div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Index;
