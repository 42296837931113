
import "./App.css";
import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Aos from "aos";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Index from "./Components/Index";
import { useEffect } from "react";
// import Policy from "./Components/Policy";



function App() {
  useEffect(() => {
    Aos.init({
        offset: 100, // offset (in px) from the original trigger point
        duration: 500, // values from 0 to 3000, with step 50ms
        easing: "ease-out",
        once: false,
    });
  }, []);
  return (
    <div className="">
      <Router>
        <ToastContainer theme='colored' />

        <Routes>
          <Route path="" Component={Index} />
          {/* <Route path="/policy" Component={Policy} /> */}
        </Routes>
      </Router>
    </div>
  );
}

export default App;
